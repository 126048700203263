export default class CodeLanguages{
    constructor(){
       this.items = [
        {
            id: 1,
            value: "json",
            label: "enum.code_languages.json.title",
        },
        {
            id: 2,
            value: "javascript",
            label: "enum.code_languages.js.title",
        },
        {
            id: 3,
            value: "html",
            label: "enum.code_languages.html.title",
        },
        {
            id: 4,
            value: "xml",
            label: "enum.code_languages.xml.title",
        },
        {
            id: 5,
            value: "css",
            label: "enum.code_languages.css.title",
        },
        {
            id: 6,
            value: "mysql",
            label: "enum.code_languages.mysql.title",
        },
        {
            id: 7,
            value: "text",
            label: "enum.code_languages.text.title",
        }
       ] 
    }
}